<template>
  <div class="w-full xl:px-36 sm:px-8">
    <div>
      <div class="flex flex-row items-center py-4">
        <h1 class="text-lg font-bold inline-flex items-center px-3 py-3">
          <strong>Subir clientes desde un archivo</strong>
        </h1>
        <div class="ml-auto">
          <sa-button type="primary" class="items-center px-3 py-3 font-bold rounded" @click="save" :disabled="this.fileList.length === 0">
            {{ saveButtonLabel }}
          </sa-button>
        </div>
      </div>
      <div class="flex flex-col space-y-4">
        <content-card v-loading.fullscreen.lock="loading">
          <div class="w-full font-bold text-md">
            <slot name="title">
              El archivo deberá estar en formato csv y contener los sig. campos: FullName, Email, Phone, Dni, ContactDescription.
              <span>
                <a href="https://s3.amazonaws.com/documents.prod.samiiweb.com/csv/Clientes+csv.csv" target="_blank">Descargar archivo da click aquí</a>
              </span>
            </slot>
          </div>
          <el-form
            :model="form"
            :rules="rules"
            ref="myDataForm"
            v-loading="loading"
          >
            <el-form-item prop="file" :style="{ width: this.isMobile ? '100%' : '30%' }">
              <el-upload
                class="upload-csv"
                action="null"
                :before-upload="beforeUpload"
                :before-remove="beforeRemove"
                :on-change="handleChange"
                :on-remove="handleRemove"
                :file-list="this.fileList"
                :limit="1"
                :auto-upload="false"
                accept=".csv"
              >
                <el-button size="small" type="primary" :disabled="this.fileList.length === 1">Seleccionar archivo</el-button>
                <div slot="tip" class="el-upload__tip">archivo csv</div>
              </el-upload>
            </el-form-item>
          </el-form>
        </content-card>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import error from "@/mixins/error";
import AdminService from "@/services/AdminService";
import responsiveSize from "@/mixins/responsiveSize";

export default {
  name: "UploadClientFileForm",
  mixins: [error, responsiveSize],
  props: {
    saveButtonLabel: {
      type: String,
      default: () => "Subir",
    },
    callback: {
      type: Function,
      default: () => { },
    },
  },
  components: {
    ContentCard: () => import("@/components/molecules/ContentCard"),
  },
  data() {
    return {
      loading: false,
      errorMessage: undefined,
      fileList: [],
      form: {
        file: undefined,
      },
      rules: {},
      isMobile: false,
    };
  },
  created() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  mounted() {

  },
  watch: {
    errorMessage: {
      deep: true,
      handler: _.debounce(async function () {
        if (this.errorMessage) {
          await this.$toastr.e(this.errorMessage);
          this.errorMessage = undefined;
        }
      }, 300),
    },
  },
  methods: {
    resize() {
      const percentageWidth = this.getPercentageWidth();
      this.isMobile = percentageWidth === '90%';
    },
    beforeUpload(file) {
      // Check if the file type is a CSV
      const isCSV = file.type === 'text/csv';
      if (!isCSV) {
        this.$message.error('El archivo seleccionado no es un csv');
      }
      // Return the check. If it's false, it stops the upload process.
      return isCSV;
    },
    beforeRemove(file) {
      return this.$confirm(`¿Desea eliminar el archivo ${ file.name } ?`);
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
    clearState() {
      this.fileList = [];
    },
    async save() {
      this.loading = true;
      try {
        const data = new FormData();
        data.append("file", this.fileList[0].raw);
        await AdminService.UploadClients.update(data, this.$store.state.business.id);
        this.clearState();
        this.$toastr.s("Clientes importados correctamente");
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
        if (this.callback && typeof this.callback === "function")
          this.callback();
      }
    },
  },
};
</script>
